import { createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPost } from '../../app/api';
import qs from "qs";
import {serialize} from "../../app/utils";

export const URL_PREFIX = '/web/widget';
export const WIDGET_CACHE_TIME = 60000;

export const webWidgetSlice = createSlice({
    name: 'webWidget',
    initialState: {
        isSubmitting: false,
        lastError: null,
        itemsRequireUpdate: true,
        list: {
            count: 0,
            items: []
        },
        publicList: {
            count: 0,
            items: []
        },
        item: null
    },
    reducers: {
        loading: (state, action) => {
            state.isSubmitting = true;
            state.lastError = null;
        },
        setError: (state, action) => {
            state.isSubmitting = false;
            state.lastError = action.payload;
        },
        itemsRequireUpdate: (state) => {
            state.itemsRequireUpdate = true;
        },
        setList: (state, action) => {
            state.itemsRequireUpdate = false;
            state.isSubmitting = false;
            state.list = action.payload;
        },
        setPublicList: (state, action) => {
            state.itemsRequireUpdate = false;
            state.isSubmitting = false;
            state.publicList = action.payload;
        },
        setItem: (state, action) => {
            state.itemsRequireUpdate = false;
            state.isSubmitting = false;
            state.item = action.payload;
        },
    }
});

export const { loading, setError, itemsRequireUpdate, setList, setPublicList, setItem } = webWidgetSlice.actions;

export const selectLoadingState = state => ({
    isSubmitting: state.webWidget.isSubmitting,
    lastError: state.webWidget.lastError
});
export const selectItemsNeedUpdate = state => state.webWidget.itemsRequireUpdate;
export const selectWidgetList = state => state.webWidget.list;
export const selectWidgetPublicList = state => state.webWidget.publicList;
export const selectWidgetItem = state => state.webWidget.item;

export async function getWidgetSuggestion(search, { type, site, status, isPublic }) {
    let query = qs.stringify({ search, filter: { type, site, status, isPublic } });

    try {
        let result = await apiGet(`${URL_PREFIX}/${query ? ('?' + query) : ''}`, WIDGET_CACHE_TIME);
        return result ? result.items : [];
    } catch(err) {
        return [{"name":"Ошибка", "title": err ? (err.message || err) : 'Неизвестная ошибка'}];
    }
};

export const getWidgetList = (page, limit, filter) => dispatch => {
    let query = qs.stringify({ page, limit, filter });

    dispatch(loading());
    return apiGet(`${URL_PREFIX}/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getWidgetPublicList = (page, limit, filter = {}) => dispatch => {
    let query = qs.stringify({ page, limit, filter: { isPublic: true, ...filter } });

    dispatch(loading());
    return apiGet(`${URL_PREFIX}/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setPublicList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const loadWidget = (id) => {
    return apiGet(`${URL_PREFIX}/${id}`, WIDGET_CACHE_TIME)
        .then(result => {
            if (result._id) {
                return result;
            } else {
                return null;
            }
        })
        .catch(err => {
            return null;
        });
};

export const getWidget = (id) => dispatch => {
    dispatch(loading());
    return apiGet(`${URL_PREFIX}/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setItem(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
            return null;
        });
};

export const updateWidget = (id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`${URL_PREFIX}/${id}`, data, 'PUT')
        .then(result => {
            if (result && result._id) {
                dispatch(setItem(result));
                dispatch(itemsRequireUpdate());
                return result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const deleteWidget = (id) => dispatch => {
    dispatch(loading());
    return apiPost(`${URL_PREFIX}/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setItem(null));
                dispatch(itemsRequireUpdate());
                return true;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const createWidget = (data) => dispatch => {
    dispatch(loading());
    return apiPost(`${URL_PREFIX}`, data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setItem(result.result));
                dispatch(itemsRequireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return new Error(result.error);
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export default webWidgetSlice.reducer;