import { createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPost } from '../../app/api';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        serverIsAvailable: false,
        login: {
            attempt: 0,
            lastError: null
        },
        allow: {
            project: false
        }
    },
    reducers: {
        resetLogin: state => {
            this.login = {
                attempt: 0,
                lastError: null
            };
        },
        failAttempt: (state, action) => {
            state.login.attempt += 1;
            state.login.lastError = action.payload;
        },
        login: (state, action) => {
            state.user = action.payload;
        },
        logout: state => {
            state.user = null;
        },
        setLockState: (state, action) => {
            state.allow = Object.assign({}, {
                project: false
            }, action.payload);
        },
        setProjectContacts: (state, action) => {
            state.user.contacts = action.payload;
        },
        setServerState: (state, action) => {
            state.serverIsAvailable = !!action.payload;
        }
    },
});

export const { resetLogin, failAttempt, login, logout, setServerState, setLockState, setProjectContacts } = authSlice.actions;

export const selectUser = state => state.auth.user;
export const selectAllow = state => state.auth.allow;
export const selectRoles = state => (roleName) => state.auth.user.access.roles.includes(roleName);
export const selectLoginState = state => state.auth.login;
export const selectServerState = state => state.auth.serverIsAvailable;
export const selectProjectFeatures = state => state.auth.user.access.features || [];
export const selectProjectSites = state => state.auth.user.sites || [];

export const checkState = () => dispatch => {
    return apiGet('/_state')
        .then(state => {
            if (state.auth && state.user) {
                dispatch(login(state.user));
                if (state.allow) {
                    dispatch(setLockState(state.allow));
                }
            }
            dispatch(setServerState(true));
        })
        .catch(err => {
            dispatch(setServerState(false));
        });
};



export const logoutUser = () => dispatch => {
    return apiGet('/auth/logout')
        .then(state => {
            if (!state.auth && !state.user) {
                dispatch(logout());
            }
        })
        .catch(err => {});
};

export const authUser = (username, password) => dispatch => {
    return apiPost('/auth/login', {username, password})
        .then(result => {
            if (result.auth && result.user) {
                dispatch(login(result.user));
            } else {
                dispatch(failAttempt(result.error));
            }
        })
        .catch(err => {
            dispatch(failAttempt(err));
        });
};

export const saveProjectSettings = (settings) => dispatch => {
    return apiPost('/project/', settings, "PUT")
        .then(result => {
            if (result.result === true) {
                dispatch(setProjectContacts(settings));
                return true;
            } else {
                return false;
            }
        })
        .catch(err => {});
};

export const unlockProject = (password) => dispatch => {
    return apiPost('/project/_unlock', {password}, "PUT")
        .then(result => {
            if (result.result === true) {
                dispatch(setLockState({project: true}));
                return true;
            } else {
                return false;
            }
        })
        .catch(err => {});
};

export const lockProject = () => dispatch => {
    return apiGet('/project/_lock')
        .then(state => {
            dispatch(setLockState({project: false}));
        })
        .catch(err => {});
};

export default authSlice.reducer;