import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from "./app/error/ErrorBoundary";
import { LoadingScreen } from './app/loading/LoadingScreen';
import './App.scss';
import Router from "./Router";
import { useDispatch, useSelector } from 'react-redux';
import {
    checkState,
    selectServerState
} from './features/auth/authSlice';

function App() {
    const dispatch = useDispatch();
    const serverIsAvailable = useSelector(selectServerState);

    useEffect(() => {
        dispatch(checkState());
    });

    return (
        <ErrorBoundary>
            <Suspense fallback={<LoadingScreen
                active={true}
                className="is-dark"
                message="Идет загрузка"
            />}>
                {serverIsAvailable ? <Router /> : <LoadingScreen
                    active={true}
                    className="is-dark"
                    message="Идет загрузка"
                />}
            </Suspense>
        </ErrorBoundary>
    );
}

export default App;
