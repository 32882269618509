import React from 'react';
import { Centered } from '../layout/centered/Centered';
import styles from "./styles.module.scss";

export const ErrorScreen = (props) => {
  return (<Centered className={styles.container} rootClassName="is-transparent">
      {props.code?<h1>{props.code}</h1>:null}

      <p className="title is-3"><strong>{props.title}</strong></p>
      <p className="subtitle is-4">{props.message}</p>
      {props.action}
  </Centered>);
}

export const InternalError = (props) => {
  return (<ErrorScreen 
      code="500"
      title="Internal Error"
      message="Our website encountered an error. We are working on it!"
      action={<a href="/" className="button is-primary">Go back home</a>}
    />);
}

export const PageNotFound = (props) => {
  return (<ErrorScreen 
      code="404"
      title="Page not found :("
      message="The requested page could not be found."
      action={<a href="/" className="button is-primary">Go back home</a>}
    />);
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    console.log(`Found error!`);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    console.log(`ERROR INFO: `, error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return (<InternalError />);
    }

    return this.props.children; 
  }
}