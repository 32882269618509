import { createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPost } from '../../app/api';
import qs from "qs";
import {Hash} from "../../app/utils";

export const catalogSlice = createSlice({
    name: 'catalog',
    initialState: {
        isSubmitting: false,
        lastError: null,
        categoriesRequireUpdate: true,
        categoryList: {
            count: 0,
            items: []
        },
        categoryPane: null,
        categorySubPane: null,
        productsRequireUpdate: true,
        productList: {
            count: 0,
            items: []
        },
        productPane: null,
        productSubPane: null
    },
    reducers: {
        loading: (state, action) => {
            state.isSubmitting = true;
            state.lastError = null;
        },
        setError: (state, action) => {
            state.isSubmitting = false;
            state.lastError = action.payload;
        },
        setCategoryList: (state, action) => {
            state.categoriesRequireUpdate = false;
            state.isSubmitting = false;
            state.categoryList = action.payload;
        },
        setCategoryPane: (state, action) => {
            state.categoriesRequireUpdate = false;
            state.isSubmitting = false;
            state.categoryPane = action.payload;
        },
        categoriesRequireUpdate: (state) => {
            state.categoriesRequireUpdate = true;
        },
        setProductList: (state, action) => {
            state.productsRequireUpdate = false;
            state.isSubmitting = false;
            state.productList = action.payload;
        },
        setProductPane: (state, action) => {
            state.productsRequireUpdate = false;
            state.isSubmitting = false;
            state.productPane = action.payload;
        },
        productsRequireUpdate: (state) => {
            state.productsRequireUpdate = true;
        },
    }
});

export const { loading , setError,
    setCategoryList, setCategoryPane, categoriesRequireUpdate,
    setProductList, setProductPane, productsRequireUpdate
} = catalogSlice.actions;

export const selectLoadingState = state => ({
    isSubmitting: state.catalog.isSubmitting,
    lastError: state.catalog.lastError
});
export const selectCategoryList = state => state.catalog.categoryList;
export const selectProductList = state => state.catalog.productList;
export const selectOpenedCategory = state => state.catalog.categoryPane;
export const selectOpenedProduct = state => state.catalog.productPane;
export const selectCategoryNeedToUpdate = state => state.catalog.categoriesRequireUpdate;
export const selectProductNeedToUpdate = state => state.catalog.productsRequireUpdate;

export const createProduct = (data) => dispatch => {
    dispatch(loading());
    return apiPost('/catalog/product', data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setCategoryPane(result.result));
                dispatch(productsRequireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const createCategory = (data) => dispatch => {
    dispatch(loading());
    return apiPost('/catalog/category', data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setCategoryPane(result.result));
                dispatch(categoriesRequireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export async function getCategorySuggestion(search, {type, isMaterial, isExtended, isRoot, category}) {
    let query = qs.stringify({ search, filter: { type, isMaterial, isExtended, isRoot, category} });

    try {
        let result = await apiGet(`/catalog/category/${query ? ('?' + query) : ''}`);
        return result.items;
    } catch(err) {
        return [{"name":"Ошибка", "title": err ? (err.message || err) : 'Неизвестная ошибка'}];
    }
};

export async function getSubCategories(category) {
    let query = qs.stringify({ filter: { category } });

    try {
        let result = await apiGet(`/catalog/category/${query ? ('?' + query) : ''}`);
        return result.items;
    } catch(err) {
        return [{"name":"Ошибка", "title": err ? (err.message || err) : 'Неизвестная ошибка'}];
    }
};

export async function getProductSuggestion(search, { type, isMaterial, category, isExtended }) {
    let query = qs.stringify({ search, filter: { type, isMaterial, category, isExtended } });

    try {
        let result = await apiGet(`/catalog/product/${query ? ('?' + query) : ''}`);
        return result.items;
    } catch(err) {
        return [{"name":"Ошибка", "title": err ? (err.message || err) : 'Неизвестная ошибка'}];
    }
};

export const loadCategoryList = (page, limit) => dispatch => {
    let query = qs.stringify({ page, limit, filter: { isRoot: true } });

    dispatch(loading());
    return apiGet(`/catalog/category/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setCategoryList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const loadProductList = (page, limit) => dispatch => {
    let query = qs.stringify({ page, limit, filter: {} });

    dispatch(loading());
    return apiGet(`/catalog/product/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setProductList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getCategory = (id) => dispatch => {
    dispatch(loading());
    return apiGet(`/catalog/category/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setCategoryPane(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getProduct = (id) => dispatch => {
    dispatch(loading());
    return apiGet(`/catalog/product/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setProductPane(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getProductData = (id) => {
    return apiGet(`/catalog/product/${id}`);
};

export const updateCategory = (id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`/catalog/category/${id}`, data, 'PUT', true, ['category'])
        .then(result => {
            if (result && result._id) {
                dispatch(setCategoryPane(result));
                dispatch(categoriesRequireUpdate());
                return result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const updateProduct = (id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`/catalog/category/${id}`, data, 'PUT', true, ['category'])
        .then(result => {
            if (result && result._id) {
                dispatch(setProductPane(result));
                dispatch(productsRequireUpdate());
                return result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const deleteCategory = (id) => dispatch => {
    dispatch(loading());
    return apiPost(`/catalog/category/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setCategoryPane(null));
                dispatch(categoriesRequireUpdate());
                Hash.clear();
                return true;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};


export const deleteProduct = (id) => dispatch => {
    dispatch(loading());
    return apiPost(`/catalog/category/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setProductPane(null));
                dispatch(productsRequireUpdate());
                Hash.clear();
                return true;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export default catalogSlice.reducer;