import { createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPost } from '../../app/api';
import qs from "qs";
import {casesRequireUpdate} from "../case/caseSlice";
import pick from "lodash/pick";

const MODULE_NAME = 'order';

export const orderSlice = createSlice({
    name: MODULE_NAME,
    initialState: {
        isSubmitting: false,
        lastError: null,
        updateRequired: true,
        list: {
            count: 0,
            items: []
        },
        selectedVariant: null,
        item: null
    },
    reducers: {
        loading: (state, action) => {
            state.isSubmitting = true;
            state.lastError = null;
        },
        setError: (state, action) => {
            state.isSubmitting = false;
            state.lastError = action.payload;
        },
        requireUpdate: (state) => {
            state.updateRequired = true;
        },
        setOrderList: (state, action) => {
            state.updateRequired = false;
            state.isSubmitting = false;
            state.list = action.payload;
        },
        setOrderItem: (state, action) => {
            state.updateRequired = false;
            state.isSubmitting = false;
            state.item = action.payload;
            if (action.payload) {
                state.selectedVariant = action.payload.variants.filter(it => it.main).pop()._id;
            }
        },
        selectOrderVariant: (state, action) => {
            state.selectedVariant = action.payload;
        }
    }
});

export const { loading , setError, requireUpdate, setOrderList, setOrderItem, selectOrderVariant } = orderSlice.actions;

export const selectLoadingState = state => ({
    isSubmitting: state[MODULE_NAME].isSubmitting,
    lastError: state[MODULE_NAME].lastError
});

export const selectOrderList = state => state[MODULE_NAME].list;
export const selectOrder = state => state[MODULE_NAME].item;
export const selectOrderInfo = state => state[MODULE_NAME].item ? ({
    _id: state[MODULE_NAME].item._id,
    name: state[MODULE_NAME].item.name,
    variants: state[MODULE_NAME].item.variants,
    selected: state[MODULE_NAME].item.selectedVariant
}) : null;
export const selectOrderItems = state => pick(state[MODULE_NAME].item, ['_id','items']);
export const selectOrderNeedToUpdate = state => state[MODULE_NAME].updateRequired;

export const createOrder = (data) => dispatch => {
    dispatch(loading());
    return apiPost(`/catalog/${MODULE_NAME}`, data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setOrderItem(result.result));
                dispatch(requireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const checkOrder = (id) => {
    return apiGet(`/catalog/${MODULE_NAME}/${id}`)
        .then(result => {
            if (result._id) {
                return result;
            } else {
                return null;
            }
        })
        .catch(err => {});
};

export const getOrder = (id) => dispatch => {
    dispatch(loading());
    return apiGet(`/catalog/${MODULE_NAME}/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setOrderItem(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const loadOrderList = (page, limit) => dispatch => {
    let query = qs.stringify({ page, limit, filter: {} });

    dispatch(loading());
    return apiGet(`/catalog/${MODULE_NAME}/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setOrderList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const updateOrderSilent = (id, data)  => {
    //dispatch(loading());
    return apiPost(`/catalog/${MODULE_NAME}/${id}`, data, 'PUT', true, [])
        .then(result => {
            if (result && result._id) {
                return result._id;
            } else {
                return null;
            }
        })
        .catch(err => {
        });
};

export const updateOrder = (id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`/catalog/${MODULE_NAME}/${id}`, data, 'PUT', true, [])
        .then(result => {
            if (result && result._id) {
                dispatch(setOrderItem(result));
                dispatch(casesRequireUpdate());
                return result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const deleteOrder = (id) => dispatch => {
    dispatch(loading());
    return apiPost(`/catalog/${MODULE_NAME}/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setOrderItem(null));
                dispatch(requireUpdate());
                return true;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export default orderSlice.reducer;