import { createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPost } from '../../app/api';
import qs from "qs";
import {serialize} from "../../app/utils";

export const URL_PREFIX = '/web/site';
export const SITE_CACHE_TIME = 60000;

export const webSlice = createSlice({
    name: 'web',
    initialState: {
        isSubmitting: false,
        lastError: null,
        itemsRequireUpdate: true,
        sitesList: {
            count: 0,
            items: []
        },
        siteItem: null
    },
    reducers: {
        loading: (state, action) => {
            state.isSubmitting = true;
            state.lastError = null;
        },
        setError: (state, action) => {
            state.isSubmitting = false;
            state.lastError = action.payload;
        },
        itemsRequireUpdate: (state) => {
            state.itemsRequireUpdate = true;
        },
        setSitesList: (state, action) => {
            state.itemsRequireUpdate = false;
            state.isSubmitting = false;
            state.sitesList = action.payload;
        },
        setSiteItem: (state, action) => {
            state.itemsRequireUpdate = false;
            state.isSubmitting = false;
            state.siteItem = action.payload;
        },
    }
});

export const { loading, setError, itemsRequireUpdate, setSitesList, setSiteItem } = webSlice.actions;

export const selectLoadingState = state => ({
    isSubmitting: state.web.isSubmitting,
    lastError: state.web.lastError
});
export const selectItemsNeedUpdate = state => state.web.itemsRequireUpdate;
export const selectSitesList = state => state.web.sitesList;
export const selectSiteItem = state => state.web.siteItem;
export const selectSiteStatus = state => state.web.siteItem ? state.web.siteItem.status : null;

export async function getSiteSuggestion(search, { type, site, status, isPublic }) {
    let query = qs.stringify({ search, filter: { type, site, status, isPublic } });

    try {
        let result = await apiGet(`${URL_PREFIX}/${query ? ('?' + query) : ''}`, SITE_CACHE_TIME);
        return result ? result.items : [];
    } catch(err) {
        return [{"name":"Ошибка", "title": err ? (err.message || err) : 'Неизвестная ошибка'}];
    }
};

export const getSiteList = (page, limit) => dispatch => {
    let query = serialize({ page, limit });

    dispatch(loading());
    return apiGet(`${URL_PREFIX}/${query ? ('?'+query): ''}`)
        .then(result => {
            if (result.items) {
                dispatch(setSitesList(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const getSite = (id) => dispatch => {
    dispatch(loading());
    return apiGet(`${URL_PREFIX}/${id}`)
        .then(result => {
            if (result._id) {
                dispatch(setSiteItem(result));
                return result;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const loadReport = (id) => {
    return apiGet(`${URL_PREFIX}/${id}/_report`, 10000)
        .then(result => {
            if (result.report) {
                return result.report;
            } else {
                return null;
            }
        })
        .catch(err => {
            return null;
        });
};

export const updateSite = (id, data) => dispatch => {
    //dispatch(loading());
    return apiPost(`${URL_PREFIX}/${id}`, data, 'PUT')
        .then(result => {
            if (result && result._id) {
                dispatch(setSiteItem(result));
                dispatch(itemsRequireUpdate());
                return result._id;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const deleteSite = (id) => dispatch => {
    dispatch(loading());
    return apiPost(`${URL_PREFIX}/${id}`, {}, 'DELETE')
        .then(result => {
            if (result && result.result === true) {
                dispatch(setSiteItem(null));
                dispatch(itemsRequireUpdate());
                return true;
            } else {
                dispatch(setError(result.error));
                return null;
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export const createSite = (data) => dispatch => {
    dispatch(loading());
    return apiPost(`${URL_PREFIX}`, data)
        .then(result => {
            if (result.result && result.result._id) {
                dispatch(setSiteItem(result.result));
                dispatch(itemsRequireUpdate());
                return result.result._id;
            } else {
                dispatch(setError(result.error));
                return new Error(result.error);
            }
        })
        .catch(err => {
            dispatch(setError(err));
        });
};

export default webSlice.reducer;